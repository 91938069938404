import bowser from "bowser";
import { ApolloClient } from "@apollo/client/core/ApolloClient.js";
import type { LangCode } from "../../../_common/types.js";
import {
    SaveWebPushSubscriptionData,
    SaveWebPushSubscriptionInput,
    SAVE_WEB_PUSH_SUBSCRIPTION,
} from "../../graphql/queries/push-notifications.js";
import { getSWVersion } from "./register-sw.js";
import { Storage } from "./storage.js";
import { getTimezoneOffset } from "../../helpers/utils.js";

// TODO: subscribe method for visual component -> with request to API
// TODO: ask permissions
// TODO: check permissions

export class PushSubscriptionApi {
    private swRegistrationPromise: Promise<ServiceWorkerRegistration>;
    private apolloClient: ApolloClient<object>;
    private storage: Storage;
    private saving: boolean = false;
    private browser: bowser.Parser.Parser;

    constructor(
        apolloClient: ApolloClient<object>,
        storage: Storage,
        swRegistrationPromise: Promise<ServiceWorkerRegistration>,
    ) {
        this.apolloClient = apolloClient;
        this.storage = storage;
        this.swRegistrationPromise = swRegistrationPromise;
        this.browser = bowser.getParser(window.navigator.userAgent);
    }

    public async getPushSubscriptionId(): Promise<string | undefined> {
        return await this.storage.getSubscriptionId();
    }

    public async getPushSubscription(): Promise<PushSubscription | null> {
        try {
            return await (
                await this.swRegistrationPromise
            ).pushManager.getSubscription();
        } catch (e) {
            return null;
        }
    }

    public async showedAlert(): Promise<void> {
        await this.storage.showedAlert();
    }
    public async shouldShowAlert(): Promise<boolean> {
        return await this.storage.shouldShowSubscriptionAlert();
    }
    public get permission() {
        return Notification.permission;
    }

    public async triggerSession(langCode: LangCode): Promise<void> {
        await this.storage.registerAction(Date.now());
        const subscription = await this.getPushSubscription();
        if (subscription) {
            const shouldSave =
                await this.storage.shouldSavePushSubscriptionToAPI();
            if (shouldSave) {
                await this.saveSubscription(subscription, langCode);
            }
        }
    }

    public async subscribe(
        pubKey: string,
        langCode: LangCode,
    ): Promise<PushSubscription | undefined> {
        try {
            if (!langCode) {
                throw new Error(
                    "Provide LangCode to subscribe to PushNotifications",
                );
            }
            if (!pubKey) {
                throw new Error(
                    "Provide VAPID Pub Key to subscribe to PushNotifications",
                );
            }
            const swRegistration = await this.swRegistrationPromise;
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(pubKey),
            };

            const subscription = await swRegistration.pushManager.subscribe(
                subscribeOptions,
            );

            await this.saveSubscription(subscription, langCode);

            return subscription;
        } catch (e) {
            console.error("Unable to subscribe to Push Notifications");
            console.error(e);
        }
    }

    public async askPermission(): Promise<NotificationPermission> {
        try {
            return await new Promise<NotificationPermission>((res, rej) => {
                const promise = Notification.requestPermission(result => {
                    res(result);
                });

                if (promise) {
                    promise.then(res, rej);
                }
            });
        } catch (e) {
            console.error("Error asking permission to show push notifications");
            throw e;
        }
    }

    private async saveSubscription(
        pushSubscription: PushSubscription,
        langCode: LangCode,
    ) {
        if (this.saving) {
            return;
        }
        try {
            this.saving = true;
            const id = await this.storage.getSubscriptionId();
            const sessionIncr = await this.storage.getSessionCounter();
            const swVer = await getSWVersion(await this.swRegistrationPromise);
            const { keys } = pushSubscription.toJSON();
            if (!keys) return;
            const input: SaveWebPushSubscriptionInput = {
                id,
                identifier: pushSubscription.endpoint,
                p256dh: keys.p256dh,
                auth: keys.auth,
                langCode,
                swVer,
                browserName: this.browser.getBrowserName(),
                browserVer: this.browser.getBrowserVersion(),
                deviceModel: navigator.platform,
                tzOffset: getTimezoneOffset(),
                deviceType: this.browser.getPlatformType(),
                sessionIncr: !id && !sessionIncr ? 1 : sessionIncr,
                lastActive: new Date().toISOString(),
            };

            const result: { data?: SaveWebPushSubscriptionData | null } =
                await this.apolloClient.mutate({
                    mutation: SAVE_WEB_PUSH_SUBSCRIPTION,
                    variables: { input },
                });

            const savedPushSubscriptionId =
                result?.data?.saveWebPushSubscription?.pushSubscription?.id;

            if (savedPushSubscriptionId) {
                await this.storage.successfullySavedPushSubscriptionToAPI(
                    savedPushSubscriptionId,
                );
            }
        } catch (e) {
            console.error(`Error while saving push subscriotion ${e}`);
        } finally {
            this.saving = false;
        }
    }

    private urlBase64ToUint8Array(base64String: string): Uint8Array {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, "+")
            .replace(/_/g, "/");

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
}
