import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import React, { useContext } from "react";
import { useParams } from "react-router";

import { GetImage, GET_IMAGE } from "../../graphql/queries/gallery.js";
import Content from "../../i18n/content.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import { container } from "../styles/common.css.js";
import ImageWithCaption from "./_common/ImageWithCaption.js";
import Spinner from "../Spinner.js";
import { imageContainer, info, title } from "./ImagePage.css.js";
import MetaTags from "../MetaTags.js";
import DownloadLink from "./_common/DownloadLink.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    path: string;
}
const ImagePage: React.FC<Props> = ({ path }) => {
    const { id } = useParams<{ id: string }>();

    const {
        langCode,
        infographicPage: { downloadInfographicButtonText },
    } = useContext(Content);

    const { loading, error, data } = useQuery<GetImage>(GET_IMAGE, {
        variables: {
            id: id,
            langCode,
        },
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }
    if (data && data.image) {
        const image = data.image;

        const { name, description, downloadUrl } = data.image.content;

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={image.contentLangs}
                    path={path}
                    title={name || ""}
                    description={description || ""}
                    mediaItem={image}
                />
                <main className={container.grid}>
                    {name && <h1 className={title}>{name}</h1>}
                    <div className={imageContainer}>
                        <ImageWithCaption
                            image={image}
                            srcSetSizes={{
                                desktop: 1168,
                                laptop: 960,
                                tablet: 736,
                            }}
                        />
                    </div>

                    <div className={info}>
                        {downloadUrl ? (
                            <DownloadLink
                                url={downloadUrl}
                                text={downloadInfographicButtonText}
                                fileName={image.id}
                                type="image"
                            />
                        ) : null}
                    </div>
                </main>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default ImagePage;
