import React, { useContext } from "react";
import { container } from "../../styles/common.css.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import TrustPilotLink from "../../common/TrustPilotLink.js";
import {
    reviewLink,
    reviewAuthor,
    reviewsContainer,
    reviewText,
    section,
    reviewLinks,
} from "./ReviewSection.css.js";
import { commonSectionTitle } from "./homeCommon.css.js";
import ProductHuntLink from "../../common/ProductHuntLink.js";
import { gaExternalLinkClick } from "../../../helpers/googleAnalytics.js";
import Content from "../../../i18n/content.js";

export interface Review {
    author: string;
    logoLink?: string;
    link?: string;
    text: string;
}

interface Props {
    title: string;
    reviews: Review[];
}

const ReviewSection: React.FC<Props> = ({ title, reviews }) => {
    const { langCode } = useContext(Content);
    return (
        <section className={section} data-nosnippet>
            <ScrollTracker position="50%" eventName="review_section_visible" />
            <div className={container.flex}>
                <h2 className={commonSectionTitle}>{title}</h2>
                <div className={reviewsContainer}>
                    {reviews.map(({ author, text, link }) => (
                        <a
                            className={reviewLink}
                            href={link}
                            key={author}
                            onClick={
                                link
                                    ? () =>
                                          gaExternalLinkClick(
                                              link,
                                              langCode,
                                              "/",
                                              "review_section",
                                          )
                                    : undefined
                            }
                        >
                            <div className={reviewText}>{text}</div>
                            <div className={reviewAuthor}>{author}</div>
                        </a>
                    ))}
                </div>
                <div className={reviewLinks}>
                    <TrustPilotLink />
                    <ProductHuntLink />
                </div>
            </div>
        </section>
    );
};

export default ReviewSection;
