import gql from "graphql-tag";
import { FeedConnection } from "./_common.js";
import { IImagePreview } from "./gallery.js";
import { imagePreview, thumbnail } from "./fragments.js";
import { QuizForFeed } from "./games.js";
import { Review } from "../../components/home/sections/ReviewSection.js";
import { Partner } from "./about.js";
import { News, NewsContent } from "./news.js";
import type { LangCode } from "../../../_common/types.js";

export interface GetHomeContentVariables {
    langCode?: LangCode;
    first: number;
}
interface SectionContent {
    title: string;
    text: string;
}
export interface HomePageContent {
    title: string;
    metaDescription: string;
    keywords?: string;
    welcomeSection: SectionContent;
    skyMapSection: SectionContent;
    simpleSection: SectionContent;
    newsSection: {
        title: string;
        text: string;
    };
    extensiveSection: SectionContent;
    observingSection: SectionContent;
    customizedSection: SectionContent;
    ARSection: SectionContent;
    timeSection: SectionContent;
    toolsSection: SectionContent;
    reviewSection: {
        title: string;
        reviews: Review[];
    };
    partnersSection: {
        title: string;
    };
    quizSection: {
        title: string;
        text: string;
        button: string;
    };
}
// type ImageForFeed = Pick<Image, "id" | "content">;

type NewsContentForHome = Pick<NewsContent, "title">;

export type NewsItemForHome = Pick<
    News<NewsContentForHome, IImagePreview>,
    "id" | "content" | "date" | "featured" | "image"
>;

export interface GetHomeContentData {
    homePage: {
        id: string;
        content: HomePageContent;
        promoItems: QuizForFeed[] | null;
    };
    newsFeed: Pick<FeedConnection<NewsItemForHome>, "edges"> | null;
    partners: Partner[] | null;
}

export const GET_HOME_CONTENT = gql`
    query getHomeContent($langCode: LangCode, $first: Int) {
        homePage: staticContent(id: "starwalk-site-home-page") {
            id
            content(langCode: $langCode)
            promoItems {
                ... on Quiz {
                    id
                    date
                    usersCount
                    questionsNumber
                    avgScore
                    content(langCode: $langCode) {
                        title
                        text
                        metaDescription
                        mediaItem {
                            id
                            ... on Image {
                                content(langCode: $langCode) {
                                    name
                                    sourceUrl
                                    thumbnails {
                                        ...thumbnail
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        newsFeed(first: $first) {
            edges {
                node {
                    id
                    date
                    featured
                    content(langCode: $langCode) {
                        title
                    }
                    image {
                        ...imagePreview
                    }
                }
            }
        }
        partners {
            id
            content {
                name
            }
            url
            imageUrl
        }
    }
    ${imagePreview}
    ${thumbnail}
`;
