import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { DOMAIN_URL } from "../../config/env.js";
import Content from "../../i18n/content.js";

interface Props {
    pageTitle: string;
    sectionName: "news" | "tutorials" | "games" | "infographics";
}
const HtmlBreadcrumbsStructuredData: React.FC<Props> = ({
    pageTitle,
    sectionName,
}) => {
    const {
        langCode,
        common: {
            layout: { menu },
        },
    } = useContext(Content);

    const sectionTitle = menu[sectionName];
    const breadcrumbs = [
        {
            position: 1,
            name: sectionTitle,
            url: `${DOMAIN_URL}/${langCode}/${sectionName}`,
        },
        {
            position: 2,
            name: pageTitle,
        },
    ];

    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbs.map(({ position, name, url }) => ({
            "@type": "ListItem",
            position,
            name,
            item: url,
        })),
    };
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(data)}</script>
        </Helmet>
    );
};
export default HtmlBreadcrumbsStructuredData;
