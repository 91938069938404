import React from "react";
import { button, container } from "./LoadMoreButton.css.js";
import CircleSpinner from "./CircleSpinner.js";

interface Props {
    loading: boolean;
    buttonText: string;
    handleLoadMore: () => void;
}

const LoadMoreButton: React.FC<Props> = ({
    loading,
    handleLoadMore,
    buttonText,
}) => {
    return (
        <div className={container}>
            <button
                className={button}
                aria-label={buttonText}
                disabled={loading}
                onClick={handleLoadMore}
            >
                <span>{buttonText}</span>
                {loading && <CircleSpinner />}
            </button>
        </div>
    );
};

export default LoadMoreButton;
