import React, { useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ApolloError } from "@apollo/client/errors";

import Content from "../../../i18n/content.js";
import ErrorPage from "./ErrorPage.js";

interface Props {
    error: ApolloError;
}
const ErrorMsg: React.FC<Props & RouteComponentProps> = ({
    staticContext,
    error,
}) => {
    const {
        common: { errors },
    } = useContext(Content);

    const errorType = error.networkError ? "network" : "default";
    const { title, message } = errors[errorType];

    if (staticContext) {
        staticContext.statusCode = 500;
    }

    return <ErrorPage title={title} message={message} />;
};

export default withRouter(ErrorMsg);
