import React, { useContext } from "react";

import Content from "../../i18n/content.js";
import { useLocation, NavLink } from "react-router-dom";
import { gaChangeLang } from "../../helpers/googleAnalytics.js";
import {
    optionStyle,
    optionsContainer,
    langPickerContainer,
    title,
    optionLink,
} from "./LangPicker.css.js";
import { activeNavLink } from "./common.css.js";
import { LangCode } from "../../../_common/types.js";

interface Props {
    closeMenu: () => void;
}

const LangPicker: React.FC<Props> = ({ closeMenu }) => {
    const { pathname } = useLocation();
    const {
        langCode: code,
        languages,
        common: {
            layout: {
                menu: { pickLang },
            },
        },
    } = useContext(Content);
    const cpath = pathname.replace(`/${code}`, "");
    return (
        <div className={langPickerContainer}>
            <div className={title}>{pickLang}</div>
            <ul className={optionsContainer}>
                {Object.keys(languages).map(langCode => (
                    <li className={optionStyle} key={langCode}>
                        <NavLink
                            className={optionLink}
                            to={`${pathname.replace(
                                `/${code}`,
                                `/${langCode}`,
                            )}`}
                            onClick={() => {
                                closeMenu();
                                gaChangeLang(
                                    code,
                                    langCode as LangCode,
                                    cpath || "/",
                                );
                            }}
                            activeClassName={activeNavLink}
                            isActive={() => langCode === code}
                        >
                            {languages[langCode]}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LangPicker;
