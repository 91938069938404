import React from "react";
import HtmlAnalytics from "../helmet/HtmlAnalytics.js";
import HtmlFacebookPixel from "../helmet/HtmlFacebookPixel.js";
import HtmlFacebook from "../helmet/HtmlFacebook.js";
import HtmlInit from "../helmet/HtmlInit.js";
import HtmlHotJar from "../helmet/HtmlHotJar.js";
import HtmlAppleIcons from "../helmet/HtmlAppleIcons.js";

const Head: React.FC = () => {
    return (
        <>
            <HtmlInit />
            <HtmlHotJar />
            <HtmlAppleIcons />
            <HtmlAnalytics />
            <HtmlFacebook />
            <HtmlFacebookPixel />
        </>
    );
};

export default Head;
