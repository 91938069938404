import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";

export interface SaveWebPushSubscriptionInput {
    id?: string | null;
    identifier: string;
    swVer: string;
    p256dh: string;
    langCode: LangCode;
    sessionIncr: number;
    auth: string;
    lastActive: string;
    tzOffset: number;
    deviceModel: string;
    browserName: string;
    browserVer: string;
    deviceType: string;
}

export interface SaveWebPushSubscriptionData {
    saveWebPushSubscription: {
        pushSubscription: {
            id: string;
        } | null;
    } | null;
}

export const SAVE_WEB_PUSH_SUBSCRIPTION = gql`
    mutation saveWebPushSubscription($input: SaveWebPushSubscriptionInput!) {
        saveWebPushSubscription(input: $input) {
            pushSubscription {
                id
            }
        }
    }
`;
