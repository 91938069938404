import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { useLocation } from "react-router-dom";
import Content from "../../i18n/content.js";
import {
    GetInfographicsFeedData,
    GetInfographicsForFeedVariables,
    GET_INFOGRAPHICS_FEED,
} from "../../graphql/queries/infographics.js";
import Spinner from "../Spinner.js";
import MetaTags from "../MetaTags.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import {
    gaFeedItemClick,
    gaLoadMoreData,
} from "../../helpers/googleAnalytics.js";
import LoadMoreButton from "../common/LoadMoreButton.js";
import AppBanners from "../common/banners/AppBanners.js";
import Card from "../common/Card.js";
import AppBannersContainer from "../common/banners/AppBannersContainer.js";
import { FeedContainer } from "../common/FeedContainers.js";
import {
    appBannerContainer,
    commonDescription,
    commonGridedTitle,
    container,
} from "../styles/common.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const first = 11;

const InfographicsFeed: React.FC = () => {
    const {
        langCode,
        langCodes,
        promoApps,
        appleAppMarketId,
        infographicsFeed: {
            title,
            metaTitle,
            metaDescription,
            description,
            keywords,
        },
        infographicsFeedMediaItems,
        common: {
            actions: { loadMoreButtonGeneral },
        },
    } = useContext(Content);

    const path = "/infographics";

    const { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const isPreview = searchParams.get("preview") === "1";

    const { loading, error, data, fetchMore } = useQuery<
        GetInfographicsFeedData,
        GetInfographicsForFeedVariables
    >(GET_INFOGRAPHICS_FEED, {
        variables: {
            langCode,
            first,
            after: undefined,
        },
        notifyOnNetworkStatusChange: true, // TODO: what is it?
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.infographicsFeed) {
        const { infographicsFeed } = data;
        const handleLoadMore = () => {
            gaLoadMoreData(langCode, "/infographics");
            fetchMore({
                variables: {
                    langCode,
                    first,
                    after: infographicsFeed.edges[
                        infographicsFeed.edges.length - 1
                    ].cursor,
                },
            });
        };

        const { totalCount, offset, edges } = infographicsFeed;

        const hasMoreNews = totalCount - offset > first;

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={path}
                    metaTitle={metaTitle}
                    title={title}
                    description={metaDescription}
                    promoAppId={appleAppMarketId}
                    mediaItem={
                        infographicsFeedMediaItems &&
                        infographicsFeedMediaItems[0]
                    }
                    noindex={isPreview}
                    keywords={keywords}
                />
                <div className={container.grid}>
                    <h1 className={commonGridedTitle}>{title}</h1>
                    {description && (
                        <p className={commonDescription}>{description}</p>
                    )}
                    <FeedContainer>
                        {edges.map(({ node: item, cursor }, i) => (
                            <Card
                                key={cursor}
                                title={item.content.title}
                                date={item.date}
                                mediaItem={item.previewImage}
                                srcSetSizes={
                                    i === 0
                                        ? {
                                            desktop: 650,
                                            laptop: 690,
                                            tablet: 710,
                                        }
                                        : {
                                            largePhone: 200,
                                        }
                                }
                                link={`/${langCode}/infographics/${item.id}`}
                                description={item.content.preview}
                                size={i === 0 ? "large" : "small"}
                                onClickHandler={() => {
                                    gaFeedItemClick(
                                        `/infographics/${item.id}`,
                                        langCode,
                                        path,
                                    );
                                }}
                            />
                        ))}
                        {hasMoreNews && (
                            <LoadMoreButton
                                loading={loading}
                                handleLoadMore={handleLoadMore}
                                buttonText={loadMoreButtonGeneral}
                            />
                        )}
                    </FeedContainer>
                    <AppBannersContainer>
                        <div className={appBannerContainer}>
                            <AppBanners
                                promoApps={promoApps}
                                location="infographicsFeed"
                                item="promoBanner"
                            />
                        </div>
                    </AppBannersContainer>
                </div>
            </>
        );
    }

    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default InfographicsFeed;
