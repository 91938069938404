import { useContext } from "react";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import { SolarObjectsContext } from "./contexts/SolarObjectsContextProvider.js";
import { MoonCalendarContentContext } from "./MoonCalendar.js";
import { PickedDateContext } from "./contexts/DateContextProvider.js";
import { phaseDescriptionStyle, phaseName } from "./MoonPhase.css.js";

const MoonPhase: React.FC = () => {
    const { phases } = useContext(MoonCalendarContentContext);
    const { getPhase } = useContext(SolarObjectsContext);
    const { _pickedDate } = useContext(PickedDateContext);

    const phase = getPhase(_pickedDate);

    if (!phases[phase]) return null;

    return (
        <>
            <h2 className={phaseName}>{phases[phase].name}</h2>
            <MarkdownRenderer
                className={phaseDescriptionStyle}
                source={phases[phase].description}
            />
        </>
    );
};

export default MoonPhase;
