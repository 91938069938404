import React, { useContext } from "react";
import { container } from "../../styles/common.css.js";
import Content from "../../../i18n/content.js";
import { TutorialGroup } from "../../../graphql/queries/tutorials.js";
import MetaTags from "../../MetaTags.js";
import TutorialGroupCard from "./TutorialGroupCard.js";
import MarkdownRenderer from "../../common/MarkdownRenderer.js";
import {
    cardsWrapper,
    pageDescription,
    pageTitle,
} from "./TutorialsList.css.js";

interface Props {
    tutorialGroups: TutorialGroup[];
}

const TutorialsList: React.FC<Props> = ({ tutorialGroups }) => {
    const {
        langCode,
        langCodes,
        appleAppMarketId,
        tutorials: { title, metaTitle, metaDescription, description, keywords },
        tutorialsMediaItems,
    } = useContext(Content);

    return (
        <>
            <MetaTags
                currentLang={langCode}
                langs={langCodes}
                path={"/tutorials"}
                metaTitle={metaTitle}
                title={title}
                description={metaDescription}
                promoAppId={appleAppMarketId}
                mediaItem={tutorialsMediaItems && tutorialsMediaItems[0]}
                keywords={keywords}
            />

            <main className={container.grid}>
                <h1 className={pageTitle}>{title}</h1>
                <div className={pageDescription}>
                    <MarkdownRenderer source={description} />
                </div>
                <div className={cardsWrapper}>
                    {tutorialGroups.map(group => (
                        <TutorialGroupCard
                            key={group.id}
                            tutorialGroup={group}
                            srcSetSizes={{
                                desktop: 568,
                                laptop: 464,
                                tablet: 736,
                            }}
                        />
                    ))}
                </div>
            </main>
        </>
    );
};

export default TutorialsList;
