import { useContext, useState } from "react";
import { CurrentGeoLocation } from "../contexts/GeoLocationContextProvider.js";
import { Field } from "react-final-form";
import Icon from "../../svg/Icon.js";
import CircleSpinner2 from "../../common/CircleSpinner2.js";
import {
    currentLocationButton,
    currentLocationButtonError,
    iconContainer,
} from "./DeviceGeoLocationPicker.css.js";
import { MoonCalendarContentContext } from "../MoonCalendar.js";

interface Props {
    updateForm: (geoLocation: CurrentGeoLocation) => void;
}

const DeviceGeoLocationPicker: React.FC<Props> = ({ updateForm }) => {
    const {
        locationPicker: { useDeviceLocation, useDeviceLocationError },
    } = useContext(MoonCalendarContentContext);
    const [state, setState] = useState<"requesting" | "denied" | null>(null);
    if (!navigator.geolocation) return null;

    const requestLocation = () => {
        setState("requesting");
        navigator.geolocation.getCurrentPosition(
            position => {
                updateForm({
                    type: "Current",
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    altitude: position.coords.altitude || 0,
                });
                setState(null);
            },
            () => {
                setState("denied");
            },
        );
    };

    return (
        <Field<"City" | "Current" | "Custom">
            name="geoLocation.type"
            render={({ input: { value } }) => (
                <button
                    className={
                        state === "denied"
                            ? currentLocationButtonError
                            : currentLocationButton
                    }
                    type="button"
                    onClick={requestLocation}
                    disabled={state !== null}
                >
                    <span className={iconContainer}>
                        {state == "requesting" ? (
                            <CircleSpinner2 />
                        ) : (
                            <Icon
                                k={
                                    state === "denied"
                                        ? "error"
                                        : value === "Current"
                                        ? "check"
                                        : "blank"
                                }
                            />
                        )}
                    </span>
                    <span>
                        {state === "denied"
                            ? useDeviceLocationError
                            : useDeviceLocation}
                    </span>
                </button>
            )}
        />
    );
};

export default DeviceGeoLocationPicker;
