import { useContext } from "react";
import { SolarObjectsContext } from "./contexts/SolarObjectsContextProvider.js";
import { PickedDateContext } from "./contexts/DateContextProvider.js";
import {
    iconContainer,
    paramsGridContainer,
    paramValue,
    paramKey,
    paramsContainer,
    timeBlock,
    timesContainer,
    middleParamValue,
} from "./Params.css.js";
import { GeoLocationContext } from "./contexts/GeoLocationContextProvider.js";
import { MoonCalendarContentContext } from "./MoonCalendar.js";
import { abbrevieteDegrees } from "../../helpers/utils.js";
import Icon from "../svg/Icon.js";

const formatTime = (m: number): string => {
    const hh = Math.floor(m / 60);
    const hours = `${hh}`.padStart(2, "0");
    const mins = `${m - hh * 60}`.padStart(2, "0");
    return hours + ":" + mins;
};

const Params: React.FC = () => {
    const { params } = useContext(MoonCalendarContentContext);
    const { getParams } = useContext(SolarObjectsContext);
    useContext(GeoLocationContext);
    const { _pickedDate } = useContext(PickedDateContext);

    const { illumination, age, angularSize, magnitude } =
        getParams(_pickedDate);

    return (
        <div className={paramsContainer}>
            <MoonAppearances />
            <div className={paramsGridContainer}>
                <Param k={params.magnitude} v={magnitude.toFixed(1)} m />
                <Param k={params.size} v={abbrevieteDegrees(angularSize)} />
                <Param
                    k={params.illumination}
                    v={illumination.toFixed(1) + "%"}
                    m
                />
                <Param k={params.age} v={age.toFixed(1)} s={params.days} />
            </div>
        </div>
    );
};

interface ParamProps {
    k: string;
    v: string;
    s?: string;
    m?: boolean;
}

const Param: React.FC<ParamProps> = ({ k, v, s, m }) => {
    return (
        <>
            <span className={paramKey}>{k + ":"}</span>
            <span
                className={m ? middleParamValue : paramValue}
                suppressHydrationWarning
            >{`${v}${s ? ` ${s}` : ""}`}</span>
        </>
    );
};

const MoonAppearances: React.FC = () => {
    const { getAppearances } = useContext(SolarObjectsContext);
    const { _pickedDate } = useContext(PickedDateContext);
    const { pickedGeoLocation } = useContext(GeoLocationContext);
    const { params } = useContext(MoonCalendarContentContext);
    const appearances = getAppearances(_pickedDate);

    if (!pickedGeoLocation) {
        return <div className={timesContainer} />;
    }

    return (
        <div className={timesContainer}>
            {appearances.map((a, i) => (
                <div
                    key={a.kind + i}
                    className={timeBlock}
                    title={params[a.kind]}
                >
                    <span className={iconContainer}>
                        <Icon k={a.kind} />
                    </span>
                    <span suppressHydrationWarning>{formatTime(a.time)}</span>
                </div>
            ))}
        </div>
    );
};

export default Params;
