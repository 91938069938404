import React, { useContext } from "react";
import Content from "../../i18n/content.js";
import { cardLabel } from "./CardLabel.css.js";

interface Props {
    typename: "News" | "Infographics" | "Quiz" | "Tutorial";
}

const labelNameMap: Record<string, string> = {
    News: "news",
    Tutorial: "tutorials",
    Infographics: "infographics",
    Quiz: "games",
};

const CardLabel: React.FC<Props> = ({ typename }) => {
    const {
        common: {
            layout: { menu },
        },
    } = useContext(Content);

    return <div className={cardLabel}>{menu[labelNameMap[typename]]}</div>;
};

export default CardLabel;
