import React, { useContext } from "react";
import { credit, creditLink, creditsLayout } from "./CopyrightBlock.css.js";
import { Copyright } from "../../graphql/queries/_common.js";
import Content from "../../i18n/content.js";

interface Props {
    textCopyright?: Copyright;
}

const CopyrightBlock: React.FC<Props> = ({ textCopyright }) => {
    const {
        newsPage: {
            article: { textCredit },
        },
    } = useContext(Content);
    if (!textCopyright) {
        return null;
    }
    // TODO: check delimeter
    return (
        <div className={creditsLayout}>
            {textCopyright ? (
                <div className={credit}>
                    {textCredit}
                    <a
                        className={creditLink}
                        href={textCopyright.url}
                        rel="author"
                    >
                        {textCopyright.author || textCopyright.url}
                    </a>
                </div>
            ) : null}
        </div>
    );
};

export default CopyrightBlock;
