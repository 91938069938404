import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { gaNavClick } from "../../helpers/googleAnalytics.js";
import {
    backLink,
    breadcrumb,
    breadcrumbsContainer,
    homeBreadcrumb,
    convertableIcon,
} from "./Breadcrumbs.css.js";
import Content from "../../i18n/content.js";
import Icon from "../svg/Icon.js";
import { container } from "../styles/common.css.js";

interface Props {
    url: string;
    title: string;
    location: string;
    containerClass?: "flex" | "flex680" | "flex750" | "flex1000";
}
const Breadcrumbs: React.FC<Props> = ({
    url,
    title,
    location,
    containerClass = "flex",
}) => {
    const {
        langCode,
        common: {
            layout: {
                menu: { home },
            },
        },
    } = useContext(Content);
    const { pathname } = useLocation();
    return (
        <nav className={container[containerClass]}>
            <ol className={breadcrumbsContainer}>
                <li className={homeBreadcrumb}>
                    <Link
                        className={backLink}
                        to={`/${langCode}`}
                        onClick={() => {
                            gaNavClick(
                                `back_to_home`,
                                "breadcrumbs",
                                langCode,
                                pathname,
                            );
                        }}
                    >
                        {home}
                    </Link>
                </li>
                <li className={breadcrumb}>
                    <Link
                        className={backLink}
                        id={`${location}-back-link`}
                        to={url}
                        onClick={() => {
                            gaNavClick(
                                `back_to_${location}`,
                                "breadcrumbs",
                                langCode,
                                pathname,
                            );
                        }}
                    >
                        <Icon className={convertableIcon} k="chevronL" />
                        {title}
                    </Link>
                </li>
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
