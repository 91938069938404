import { useMutation } from "@apollo/client/react/hooks/useMutation.js";
import React, { useContext, useState } from "react";
import { Form } from "react-final-form";
import {
    SubscribeToNewsletterInput,
    SubscribeToNewsletterPayload,
    SUBSCRIBE_TO_NEWS_LETTER,
} from "../../graphql/queries/_common.js";
import { getTimezoneOffset } from "../../helpers/utils.js";
import Content from "../../i18n/content.js";
import CircleSpinner from "../common/CircleSpinner.js";
import { successMessageButton } from "../common/commonFormStyles.css.js";
import {
    makeEmailValidator,
    makeRequiredValidator,
} from "../common/formValidators.js";
import Icon from "../svg/Icon.js";

import {
    envelopeButton,
    formSubmitError,
    messageText,
    messageWrapper,
} from "./SubscribeForm.css.js";
import TextField from "../common/form/TextField.js";
import {
    closeFormButton,
    formButton,
    formHeaderWrapper,
    formWrapper,
    popupForm,
} from "../common/form/PopupFormStyles.css.js";

interface Values {
    firstName: string;
    lastName: string;
    email: string;
}

const SubscribeForm: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [subscribeToNewsletter, { data, loading }] = useMutation<
        { result: SubscribeToNewsletterPayload },
        { input: SubscribeToNewsletterInput }
    >(SUBSCRIBE_TO_NEWS_LETTER);
    const {
        langCode,
        common: {
            emailSubscriptionForm: {
                emailInput,
                firstNameInput,
                lastNameInput,
                subscribeButton,
                okButton,
                requireMessage,
                emailValidationText,
                formHeader,
                formErrorMessage,
            },
        },
    } = useContext(Content);

    const onSubmit = async (value: Values) => {
        const dataToSave: any = {
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName,
            langCode: langCode,
            tzOffset: getTimezoneOffset(),
        };
        subscribeToNewsletter({ variables: { input: dataToSave } });
    };
    const emailValidators = [
        makeRequiredValidator(requireMessage),
        makeEmailValidator(emailValidationText),
    ];
    return (
        <>
            <button className={envelopeButton} onClick={() => setIsOpen(true)}>
                <Icon k="envelope" />
            </button>
            {isOpen && (
                <Form
                    onSubmit={onSubmit}
                    render={({
                        handleSubmit,
                        submitting,
                        validating,
                        submitSucceeded,
                        hasSubmitErrors,
                        form,
                    }) => (
                        <div className={popupForm}>
                            {submitSucceeded && data && data.result ? (
                                <div className={messageWrapper}>
                                    <p className={messageText}>
                                        {data.result.message}
                                    </p>
                                    <button
                                        className={successMessageButton}
                                        onClick={() => {
                                            form.restart();
                                            setIsOpen(false);
                                        }}
                                    >
                                        {okButton}
                                    </button>
                                </div>
                            ) : (
                                <div className={formWrapper}>
                                    <div className={formHeaderWrapper}>
                                        <span>{formHeader}</span>
                                        <span
                                            onClick={() => {
                                                form.restart();
                                                setIsOpen(false);
                                            }}
                                            className={closeFormButton}
                                        >
                                            <Icon k="close" />
                                        </span>
                                    </div>
                                    <form>
                                        <TextField
                                            name="email"
                                            type="email"
                                            label={emailInput}
                                            placeholder={`${emailInput} *`}
                                            validators={[...emailValidators]}
                                        />
                                        <TextField
                                            name="firstName"
                                            label={firstNameInput}
                                            placeholder={firstNameInput}
                                        />
                                        <TextField
                                            name="lastName"
                                            label={lastNameInput}
                                            placeholder={lastNameInput}
                                        />
                                        <button
                                            className={formButton}
                                            type="button"
                                            onClick={handleSubmit}
                                            disabled={submitting || validating}
                                        >
                                            {!loading && (
                                                <span>{subscribeButton}</span>
                                            )}
                                            {loading && <CircleSpinner />}
                                        </button>
                                        {hasSubmitErrors ? (
                                            <p className={formSubmitError}>
                                                {formErrorMessage}
                                            </p>
                                        ) : null}
                                    </form>
                                </div>
                            )}
                        </div>
                    )}
                />
            )}
        </>
    );
};

export default SubscribeForm;
