import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
// import { FormSpy } from "react-final-form";
import NumField from "./NumField.js";
import Icon from "../../svg/Icon.js";

import {
    CityGeoLocation,
    GeoLocationContext,
    PickedGeoLocation,
} from "../contexts/GeoLocationContextProvider.js";
import {
    isAltValid,
    isLatValid,
    isLngValid,
    isNumber,
    makeRequiredValidator,
} from "../../common/formValidators.js";
import {
    buttonsBlock,
    closeFormButton,
    formHeaderWrapper,
    formWrapper,
    popupForm,
} from "../../common/form/PopupFormStyles.css.js";
import { tzOffsetsList } from "../../../../_common/constants.js";
import DeviceGeoLocationPicker from "./DeviceGeoLocationPicker.js";
import {
    Timezone,
    TimezoneContext,
} from "../contexts/TimezoneContextProvider.js";
import { MoonCalendarContentContext } from "../MoonCalendar.js";
import CitySearchField from "./CitySearcher.js";
import TimezoneSelect from "./TimezoneSelect.js";
import { Decorator, FormApi } from "final-form";
import { isTimezoneSupported } from "../utils.js";
import { submitButton } from "./styles.css.js";

interface FormState {
    geoLocation: PickedGeoLocation;
    timezone: Timezone;
    timezoneOptions: Timezone[];
}
interface Props {
    onClose: () => void;
    defaultGeoLocation: CityGeoLocation;
}

const defaultTimezoneOptions = ["Current", ...tzOffsetsList];

const makeCalculator =
    (initValues: FormState): Decorator<FormState, FormState> =>
    (form: FormApi<FormState, FormState>) => {
        let p: FormState = initValues;
        const unsubscribe = form.subscribe(
            ({ values: v }) => {
                const makeChanges = ({
                    timezone,
                    timezoneOptions,
                }: Pick<FormState, "timezone" | "timezoneOptions">) => {
                    form.batch(() => {
                        form.change("timezone", timezone);
                        form.change("timezoneOptions", timezoneOptions);
                    });
                };

                if (
                    v.geoLocation.type === "City" &&
                    (p.geoLocation.type !== "City" ||
                        v.geoLocation.timezone !== p.geoLocation.timezone)
                ) {
                    if (
                        v.geoLocation.timezone &&
                        isTimezoneSupported(v.geoLocation.timezone)
                    ) {
                        makeChanges({
                            timezone: v.geoLocation.timezone,
                            timezoneOptions: [
                                v.geoLocation.timezone,
                                ...defaultTimezoneOptions,
                            ],
                        });
                    } else {
                        makeChanges({
                            timezone: "Current",
                            timezoneOptions: defaultTimezoneOptions,
                        });
                    }
                }
                p = v;
            },
            { values: true },
        );
        return unsubscribe;
    };

const GeoLocationPickerForm: React.FC<Props> = ({
    onClose,
    defaultGeoLocation,
}) => {
    const { pickGeoLocation, pickedGeoLocation } =
        useContext(GeoLocationContext);
    const { setTimezone, timezone } = useContext(TimezoneContext);
    const {
        locationPicker: {
            setCoordinates,
            currentLocation,
            manualLocation,
            latitude,
            longitude,
            altitude,
            timezone: timezoneLabel,
            searchLabel,
            searchPlaceholder,
            confirmButton,
        },
    } = useContext(MoonCalendarContentContext);

    const required = makeRequiredValidator("required");

    if (typeof timezone === "string" && timezone !== "Current") {
        defaultTimezoneOptions.unshift(timezone);
    }

    const initialValues = {
        geoLocation: pickedGeoLocation || defaultGeoLocation,
        timezone,
        timezoneOptions: defaultTimezoneOptions,
    };

    return (
        <Form<FormState, FormState>
            onSubmit={({ geoLocation, timezone }) => {
                pickGeoLocation({
                    type: "set",
                    picked: geoLocation,
                });
                setTimezone(timezone);
            }}
            initialValues={initialValues}
            decorators={[makeCalculator(initialValues)]}
            render={({ handleSubmit, valid, form }) => (
                <div className={popupForm}>
                    <form className={formWrapper}>
                        <div className={formHeaderWrapper}>
                            <span>{setCoordinates}</span>
                            <span
                                onClick={() => {
                                    form.restart();
                                    onClose();
                                }}
                                className={closeFormButton}
                            >
                                <Icon k="close" />
                            </span>
                        </div>
                        <Field<PickedGeoLocation>
                            name="geoLocation"
                            render={({ input: { value, onChange } }) => {
                                const name =
                                    value.type === "City"
                                        ? value.name
                                        : value.type === "Current"
                                        ? currentLocation
                                        : manualLocation;
                                return (
                                    <CitySearchField
                                        label={searchLabel}
                                        placeholder={searchPlaceholder}
                                        value={name}
                                        onChange={e => onChange(e)}
                                    />
                                );
                            }}
                        />
                        <Field<"City" | "Custom" | "Current">
                            name="geoLocation.type"
                            render={({ input: { value } }) => {
                                const resetGeoLocationType =
                                    value !== "Custom"
                                        ? () => {
                                              form.batch(() => {
                                                  form.change(
                                                      "geoLocation.type" as any,
                                                      "Custom",
                                                  );
                                                  form.change(
                                                      "geoLocation.id" as any,
                                                      undefined,
                                                  );
                                                  form.change(
                                                      "geoLocation.name" as any,
                                                      undefined,
                                                  );
                                                  form.change(
                                                      "geoLocation.lang" as any,
                                                      undefined,
                                                  );
                                                  form.change(
                                                      "geoLocation.langs" as any,
                                                      undefined,
                                                  );
                                                  form.change(
                                                      "geoLocation.country" as any,
                                                      undefined,
                                                  );
                                              });
                                          }
                                        : undefined;
                                return (
                                    <>
                                        <NumField
                                            name="geoLocation.latitude"
                                            label={latitude}
                                            placeholder="-90.00 ... 90.00"
                                            validators={[
                                                required,
                                                isNumber,
                                                isLatValid,
                                            ]}
                                            geoLocationTypeReset={
                                                resetGeoLocationType
                                            }
                                        />
                                        <NumField
                                            name="geoLocation.longitude"
                                            label={longitude}
                                            placeholder="-180.00 ... 180.00"
                                            validators={[
                                                required,
                                                isNumber,
                                                isLngValid,
                                            ]}
                                            geoLocationTypeReset={
                                                resetGeoLocationType
                                            }
                                        />
                                        <NumField
                                            name="geoLocation.altitude"
                                            label={altitude}
                                            placeholder="0 ... 10000"
                                            validators={[
                                                required,
                                                isNumber,
                                                isAltValid,
                                            ]}
                                            geoLocationTypeReset={
                                                resetGeoLocationType
                                            }
                                        />
                                    </>
                                );
                            }}
                        />
                        <TimezoneSelect label={timezoneLabel} />
                        <div className={buttonsBlock}>
                            <DeviceGeoLocationPicker
                                updateForm={g => {
                                    form.batch(() => {
                                        form.change("geoLocation", g);
                                        form.change("timezone", "Current");
                                    });
                                }}
                            />
                            <button
                                className={submitButton}
                                type="button"
                                disabled={!valid}
                                onClick={() => {
                                    onClose();
                                    handleSubmit();
                                }}
                            >
                                {confirmButton}
                            </button>
                        </div>
                        {/* <FormSpy
                            subscription={{ values: true }}
                            render={({ values }) => (
                                <pre
                                    style={{
                                        maxHeight: "200px",
                                        overflowY: "scroll",
                                    }}
                                >
                                    {JSON.stringify(values, undefined, 2)}
                                </pre>
                            )}
                        /> */}
                    </form>
                </div>
            )}
        />
    );
};

export default GeoLocationPickerForm;
