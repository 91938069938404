import { MenuItemsMap } from "../../i18n/content.js";
import { IconKey } from "../svg/icons-map.js";

interface MenuItemConfig {
    exact?: boolean;
    icon?: IconKey;
    dc: number; // Desktop Col
    dcs: number; // Desktop Col Span
    mr: number; // Mobile Row
    mcs: number; // Mobile Col Span
    o: number; // Open Order
    c: number; // Close Order
    p?: string; // path
}

export const menuConfig: MenuItemsMap<MenuItemConfig> = {
    home: { exact: true, dc: 1, dcs: 1, mr: 1, mcs: 3, o: 1, c: 9, p: "" },
    search: { icon: "search", dc: 7, dcs: 2, mr: 2, mcs: 4, o: 2, c: 8 },
    news: { icon: "news", dc: 2, dcs: 1, mr: 3, mcs: 4, o: 3, c: 7 },
    tutorials: { icon: "tutorials", dc: 3, dcs: 1, mr: 4, mcs: 4, o: 4, c: 6 },
    games: { icon: "games", dc: 4, dcs: 1, mr: 5, mcs: 4, o: 5, c: 5 },
    infographics: {
        icon: "infographics",
        dc: 5,
        dcs: 1,
        mr: 6,
        mcs: 4,
        o: 6,
        c: 4,
    },
    moon: {
        icon: "moon",
        dc: 6,
        dcs: 1,
        mr: 7,
        mcs: 4,
        o: 7,
        c: 3,
        p: "moon-calendar",
    },
    pickLang: { icon: "pickLang", dc: 9, dcs: 1, mr: 8, mcs: 4, o: 8, c: 2 },
    followUs: { dc: 8, dcs: 1, mr: 9, mcs: 4, o: 9, c: 1 },
};

export const getDelay = (order: number) => 40 * order;

export const menuBreakpoint = "desktop";

export const menuAnimLength = "0.2s";
