import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import BurgerMenu from "./BurgerMenu.js";
import Icon from "../svg/Icon.js";
import Logo from "../svg/Logo.js";
import { gaNavClick } from "../../helpers/googleAnalytics.js";
import Content from "../../i18n/content.js";
import SearchForm from "../search/SearchForm.js";
import LangPicker from "./LangPicker.js";
import {
    followUsContainer,
    followUsLabel,
    langSelectLabel,
    langSelectLabelIcon,
    menuGridContainer,
    menuIconStyleVariants,
    menuItemStyleVariants,
    menuItemTitleStyleVariants,
    menuNavLink,
    slidingLangPicker,
    nav,
    pickLangArrowIcon,
    styledHomeLogo,
    homeNavLink,
} from "./Menu.css.js";
import { menuConfig } from "./menu-config.js";
import SocialMediaLinks from "../common/SocialMediaLinks.js";
import { activeNavLink } from "./common.css.js";

interface Props {
    closeMenu: () => void;
    toggleMenu: () => void;
}

const makePath = (p: string): string => p && `/${p}`;

const Menu: React.FC<Props> = ({ closeMenu, toggleMenu }) => {
    const {
        langCode,
        languages,
        common: {
            layout: { menu },
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    return (
        <nav id="main-menu" className={nav}>
            <ul className={menuGridContainer}>
                <BurgerMenu toggleMenu={toggleMenu} inputId="menu-state" />
                {Object.entries(menuConfig).map(([key, { icon, exact, p }]) => (
                    <li key={key} className={menuItemStyleVariants[key]}>
                        {key === "search" ? (
                            <SearchForm key={key} navCb={closeMenu} />
                        ) : key === "followUs" ? (
                            <div
                                className={followUsContainer}
                                id="follow-us-header-nav"
                            >
                                <label className={followUsLabel}>
                                    {menu[key]}
                                </label>
                                <SocialMediaLinks position="menu" />
                            </div>
                        ) : key === "pickLang" ? (
                            <>
                                <label
                                    className={langSelectLabel}
                                    htmlFor="lang-picker-state"
                                >
                                    <span className={slidingLangPicker}>
                                        <Icon
                                            className={langSelectLabelIcon}
                                            k="pickLang"
                                        />
                                        <span
                                            className={
                                                menuItemTitleStyleVariants[key]
                                            }
                                        >
                                            {languages[langCode]}
                                        </span>
                                        <Icon
                                            className={pickLangArrowIcon}
                                            k="chevronL"
                                        />
                                    </span>
                                </label>
                                <LangPicker closeMenu={closeMenu} />
                            </>
                        ) : (
                            <NavLink
                                className={
                                    key === "home" ? homeNavLink : menuNavLink
                                }
                                exact={exact}
                                to={`/${langCode}${makePath(p ?? key)}`}
                                onClick={() => {
                                    gaNavClick(key, "menu", langCode, pathname);
                                    closeMenu();
                                }}
                                activeClassName={activeNavLink}
                                isActive={(_, location) => {
                                    const pageName =
                                        location.pathname.split("/")[2];
                                    const isQuizPage =
                                        key === "games" && pageName === "quiz";
                                    const isHomePage =
                                        location.pathname === `/${langCode}` &&
                                        key === "home";
                                    return (
                                        pageName === key ||
                                        isQuizPage ||
                                        isHomePage
                                    );
                                }}
                            >
                                {key === "home" ? (
                                    <Logo
                                        className={styledHomeLogo}
                                        showFrame={true}
                                    />
                                ) : icon ? (
                                    <Icon
                                        className={menuIconStyleVariants[key]}
                                        k={icon}
                                    />
                                ) : null}
                                <span
                                    className={menuItemTitleStyleVariants[key]}
                                >
                                    {menu[key]}
                                </span>
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Menu;
