import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { useLocation } from "react-router-dom";

import Content from "../../../i18n/content.js";
import {
    GET_GAMES_FEED,
    GetGamesFeedVariables,
    GetGamesFeedData,
} from "../../../graphql/queries/games.js";
import Spinner from "../../Spinner.js";
import MetaTags from "../../MetaTags.js";
import ErrorMsg from "../../common/errors/ErrorMsg.js";
import {
    gaFeedItemClick,
    gaLoadMoreData,
} from "../../../helpers/googleAnalytics.js";
import LoadMoreButton from "../../common/LoadMoreButton.js";
import AppBanners from "../../common/banners/AppBanners.js";
import SummaryBadge from "../quiz/SummaryBadge.js";
import AppBannersContainer from "../../common/banners/AppBannersContainer.js";
import Card from "../../common/Card.js";
import { FeedContainer } from "../../common/FeedContainers.js";
import {
    appBannerContainer,
    commonDescription,
    commonGridedTitle,
    container,
} from "../../styles/common.css.js";
import NotFoundErrorMsg from "../../common/errors/NotFoundErrorMsg.js";

const first = 11;

const GamesLoader: React.FC = () => {
    const {
        langCode,
        langCodes,
        promoApps,
        appleAppMarketId,
        gamesList: { title, metaTitle, metaDescription, description, keywords },
        gamesListMediaItems,
        common: {
            actions: { loadMoreButtonGeneral },
        },
    } = useContext(Content);

    const path = "/games";

    const { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const isPreview = searchParams.get("preview") === "1";

    const { loading, error, data, fetchMore } = useQuery<
        GetGamesFeedData,
        GetGamesFeedVariables
    >(GET_GAMES_FEED, {
        variables: {
            langCode,
            first,
            after: undefined,
        },
        notifyOnNetworkStatusChange: true, // TODO: what is it?
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.games) {
        const { games } = data;
        const handleLoadMore = () => {
            gaLoadMoreData(langCode, "/games");
            fetchMore({
                variables: {
                    langCode,
                    first,
                    after: games.edges[games.edges.length - 1].cursor,
                },
            });
        };

        const { totalCount, offset, edges } = games;

        const hasMoreNews = totalCount - offset > first;

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={path}
                    metaTitle={metaTitle}
                    title={title}
                    description={metaDescription}
                    promoAppId={appleAppMarketId}
                    mediaItem={gamesListMediaItems && gamesListMediaItems[0]}
                    noindex={isPreview}
                    keywords={keywords}
                />
                <div className={container.grid}>
                    <h1 className={commonGridedTitle}>{title}</h1>
                    {description && (
                        <p className={commonDescription}>{description}</p>
                    )}
                    <FeedContainer>
                        {edges.map(
                            (
                                {
                                    node: {
                                        id,
                                        content: {
                                            title,
                                            mediaItem,
                                            metaDescription,
                                        },
                                        date,
                                        questionsNumber,
                                        avgScore,
                                        usersCount,
                                    },
                                    cursor,
                                },
                                i,
                            ) => {
                                const to = `/quiz/${id}`;
                                return (
                                    <Card
                                        key={cursor}
                                        title={title}
                                        date={date}
                                        mediaItem={mediaItem}
                                        srcSetSizes={
                                            i === 0
                                                ? {
                                                    desktop: 650,
                                                    laptop: 690,
                                                    tablet: 710,
                                                }
                                                : {
                                                    largePhone: 200,
                                                }
                                        }
                                        link={`/${langCode}${to}`}
                                        description={metaDescription}
                                        size={i === 0 ? "large" : "small"}
                                        onClickHandler={() => {
                                            gaFeedItemClick(to, langCode, path);
                                        }}
                                    >
                                        <SummaryBadge
                                            questionsNumber={questionsNumber}
                                            avgScore={avgScore}
                                            usersCount={usersCount}
                                        />
                                    </Card>
                                );
                            },
                        )}
                        {hasMoreNews && (
                            <LoadMoreButton
                                loading={loading}
                                handleLoadMore={handleLoadMore}
                                buttonText={loadMoreButtonGeneral}
                            />
                        )}
                    </FeedContainer>

                    <AppBannersContainer>
                        <div className={appBannerContainer}>
                            <AppBanners
                                promoApps={promoApps}
                                location="gamesList"
                                item="promoBanner"
                            />
                        </div>
                    </AppBannersContainer>
                </div>
            </>
        );
    }

    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default GamesLoader;
