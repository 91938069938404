import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import Content from "../../i18n/content.js";
import MetaTags from "../MetaTags.js";
import { PromoAppSetterContext } from "../layout/PromoAppContextProvider.js";
import {
    GetHomeContentData,
    GetHomeContentVariables,
    GET_HOME_CONTENT,
} from "../../graphql/queries/home.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import Spinner from "../Spinner.js";
import PartnersSection from "./sections/PartnersSection.js";
import BackSection from "./sections/BackSection.js";
import ReviewSection from "./sections/ReviewSection.js";
import ExtensiveSection from "./sections/ExtensiveSection.js";
import NewsSection from "./sections/NewsSection.js";
import ObservingSection from "./sections/ObservingSection.js";
import IntroSection from "./sections/IntroSection.js";
import QuizSection from "./sections/QuizSection.js";
import { homeMain } from "./Home.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const Home: React.FC = () => {
    const pickPromoApp = useContext(PromoAppSetterContext);
    const { langCode, langCodes, promoApps, appleAppMarketId } =
        useContext(Content);

    useEffect(() => {
        pickPromoApp({
            type: "pick",
            apps: promoApps,
        });
    }, [pickPromoApp, promoApps]);

    const { loading, error, data } = useQuery<
        GetHomeContentData,
        GetHomeContentVariables
    >(GET_HOME_CONTENT, {
        variables: { langCode, first: 6 },
        notifyOnNetworkStatusChange: true, // TODO: what is it?
    });

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (data && data.newsFeed && data.homePage) {
        const quiz = data.homePage.promoItems?.find(
            item => item.__typename === "Quiz",
        );
        const {
            title,
            metaDescription,
            welcomeSection,
            skyMapSection,
            simpleSection,
            newsSection,
            extensiveSection,
            observingSection,
            customizedSection,
            // ARSection,
            timeSection,
            // toolsSection,
            reviewSection,
            partnersSection,
            quizSection,
            keywords,
        } = data.homePage.content;

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={""}
                    metaTitle={title}
                    title={`Star Walk 2 - ${welcomeSection.title}`}
                    description={metaDescription}
                    promoAppId={appleAppMarketId}
                    keywords={keywords}
                />
                <main className={homeMain}>
                    <IntroSection key={langCode} {...welcomeSection} />
                    <BackSection {...skyMapSection} name="skymap" />
                    <ObservingSection {...observingSection} />
                    <BackSection {...simpleSection} name="simple" />
                    <NewsSection
                        {...newsSection}
                        news={data.newsFeed.edges.map(news => news.node)}
                    />
                    <BackSection {...customizedSection} name="customized" />
                    <ExtensiveSection {...extensiveSection} />
                    <BackSection {...timeSection} name="time" />
                    <ReviewSection {...reviewSection} />
                    {data.partners && (
                        <PartnersSection
                            title={partnersSection.title}
                            partners={data.partners}
                        />
                    )}
                    {quiz && <QuizSection {...quizSection} quiz={quiz} />}
                </main>
            </>
        );
    }

    if (loading) {
        return <Spinner />;
    }

    return <NotFoundErrorMsg />;
};

export default Home;
