import React from "react";
import { Field } from "react-final-form";
import { DepartmentSelect } from "../../../graphql/queries/contacts.js";
import { formRow } from "../../styles/common.css.js";
import { AppVersionSelect, PlatformSelect } from "../ContactsForm.js";
import { commonField, fieldErrorMessage } from "./formComponents.css.js";

interface Props {
    name: string;
    label: string;
    validator: (v: string) => string | undefined;
    optionsData: DepartmentSelect | PlatformSelect | AppVersionSelect;
}

const SelectField: React.FC<Props> = ({
    name,
    optionsData,
    label,
    validator,
}) => {
    const optionsDataArray: string[][] = Object.entries(optionsData);
    const id = `${name}-select`;
    return (
        <Field
            name={name}
            render={({ input, meta, rest }) => (
                <div className={formRow}>
                    <label htmlFor={id}>{label}</label>
                    <select
                        id={id}
                        className={commonField}
                        {...input}
                        {...rest}
                    >
                        <option value="" disabled />
                        {optionsDataArray.map(
                            (item: string[], index: number) => {
                                return (
                                    <option key={index} value={item[0]}>
                                        {item[1]}
                                    </option>
                                );
                            },
                        )}
                    </select>
                    {meta.error && meta.touched && (
                        <span className={fieldErrorMessage}>{meta.error}</span>
                    )}
                </div>
            )}
            validate={validator}
        />
    );
};

export default SelectField;
