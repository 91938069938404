import React, { useContext } from "react";
import { withRouter, RouteComponentProps, useLocation } from "react-router";
import { Link } from "react-router-dom";

import Content from "../../../i18n/content.js";
import { linksWrapper, styledLink } from "./NotFoundErrorMsg.css.js";
import { gaNavClick } from "../../../helpers/googleAnalytics.js";
import ErrorPage from "./ErrorPage.js";



const NotFoundErrorMsg: React.FC<RouteComponentProps> = ({
    staticContext,
}) => {
    const {
        langCode,
        common: {
            errors: {
                notFound: {
                    title,
                    message
                }
            },
            layout: {
                menu: { home, news, games, infographics },
            },
        },
    } = useContext(Content);

    const { pathname } = useLocation();

    if (staticContext) {
        staticContext.statusCode = 404;
    }

    const links = [
        {
            key: "home",
            path: "",
            text: home,
        },
        {
            key: "news",
            path: "/news",
            text: news,
        },
        {
            key: "games",
            path: "/games",
            text: games,
        },
        {
            key: "infographics",
            path: "/infographics",
            text: infographics,
        },
    ];
    return (
        <ErrorPage title={title} message={message}>
            <div className={linksWrapper}>
                {links.map(({ key, path, text }) => (
                    <Link
                        className={styledLink}
                        key={key}
                        to={`/${langCode}${path}`}
                        onClick={() => {
                            gaNavClick(key, "page404", langCode, pathname);
                        }}
                    >
                        {text}
                    </Link>
                ))}
            </div>
        </ErrorPage>
    );
};

export default withRouter(NotFoundErrorMsg);
