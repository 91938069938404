import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { gaMarkdownLinkClick } from "../../../helpers/googleAnalytics.js";
import Content from "../../../i18n/content.js";
import { InfographicsForBanner } from "../../../graphql/queries/infographics.js";
import Icon from "../../svg/Icon.js";
import {
    button,
    icon,
    bannerText,
    imageContainer,
    banner,
    bannerContent,
} from "./common.css.js";
import MediaItem from "../../gallery/_common/MediaItem.js";

interface Props {
    item: InfographicsForBanner;
}

const InfographicsBanner: React.FC<Props> = ({ item }) => {
    const {
        id,
        content: { preview },
        previewImage,
    } = item;
    const {
        langCode,
        newsPage: {
            article: { infographicsBannerButton },
        },
    } = useContext(Content);
    const { pathname } = useLocation();

    const to = `/infographics/${id}`;

    return (
        <div className={banner}>
            <Icon className={icon} k="handPoint" />
            <div className={bannerContent}>
                <div className={imageContainer}>
                    <MediaItem
                        mediaItem={previewImage}
                        srcSetSizes={{
                            largePhone: 180,
                        }}
                    />
                </div>
                <div className={bannerText}>{preview}</div>
            </div>
            <Link
                className={button}
                to={`/${langCode}${to}`}
                onClick={() =>
                    gaMarkdownLinkClick(
                        "internal_banner",
                        to,
                        langCode,
                        pathname,
                    )
                }
            >
                {infographicsBannerButton}
            </Link>
        </div>
    );
};

export default InfographicsBanner;
