import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";
import { NewsForFeed, newsPreview } from "./news.js";
import { image } from "./fragments.js";
import { QuizForFeed, quizPreview } from "./games.js";
import { InfographicsForFeed, infographicsPreview } from "./infographics.js";
import { Tutorial, TutorialContent, tutorialPreview } from "./tutorials.js";

export interface SearchPublicationsVariables {
    key: string | null;
    langCode?: LangCode;
    first: number;
    after?: string | null;
}

export type Publication =
    | NewsForFeed
    | QuizForFeed
    | InfographicsForFeed
    | Tutorial<TutorialContent>;

export interface SearchResultPayload {
    bookmark: string | null;
    nodes: Publication[];
}
export interface SearchPublicationsData {
    searchPublications: SearchResultPayload | null;
}
export const SEARCH_PUBLICATIONS = gql`
    query searchPublications(
        $key: PublicationsSearchKey!
        $langCode: LangCode
        $first: Int
        $after: PublicationsSearchBookmark
    ) {
        searchPublications(
            key: $key
            langCode: $langCode
            first: $first
            after: $after
        ) {
            bookmark
            nodes {
                ... on News {
                    ...newsPreview
                }
                ... on Infographics {
                    ...infographicsPreview
                }
                ... on Tutorial {
                    ...tutorialPreview
                }
                ... on Quiz {
                    ...quizPreview
                }
                ... on Tutorial {
                    id
                    date
                    content(langCode: $langCode) {
                        title
                        readingTime
                        metaDescription
                    }
                }
            }
        }
    }
    ${image}
    ${newsPreview}
    ${infographicsPreview}
    ${tutorialPreview}
    ${quizPreview}
`;
export interface SearchPageDataVariables {
    langCode?: LangCode;
}
export interface SearchPageData {
    searchPageContent: {
        id: string;
        content: {
            metaTitle: string;
            metaDescription: string;
            noMoreMessage: string;
            noResultsMessage: string;
            title: string;
        };
    };
}
export const SEARCH_PAGE_DATA = gql`
    query searchPageData($langCode: LangCode) {
        searchPageContent: staticContent(id: "starwalk-site-search-page") {
            id
            content(langCode: $langCode)
        }
    }
`;
