import { useMutation } from "@apollo/client/react/hooks/useMutation.js";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { VITO_APP_ID } from "../../../_common/config.js";
import {
    CREATE_MESSAGE,
    SendMessageInput,
    SendMessagePayload,
    FormContent,
    Department,
} from "../../graphql/queries/contacts.js";

import { AppPlatform } from "../../graphql/queries/apps.js";
import CircleSpinner from "../common/CircleSpinner.js";
import Icon from "../svg/Icon.js";
import Captcha from "./Captcha.js";
import {
    buttonBox,
    closeFormButton,
    contentBox,
    errorMessageText,
    formTitle,
    formWrapper,
    messageText,
    popupMessageBox,
    popupWrapper,
    titleBox,
} from "./ContactsForm.css.js";
import {
    successMessageButton,
    sendButton,
} from "../common/commonFormStyles.css.js";
import { contactsButton } from "./Contacts.css.js";
import TextField from "./formComponents/TextField.js";
import SelectField from "./formComponents/SelectField.js";
import TextAreaField from "./formComponents/TextAreaField.js";
import {
    makeCaptchaValidator,
    makeEmailValidator,
    makeRequiredValidator,
} from "../common/formValidators.js";
import Condition from "./Condition.js";

interface Props {
    formData: FormContent;
    contactButton: string;
}
interface Values {
    department: Department;
    name: string;
    email: string;
    platform: AppPlatform;
    version: string;
    message: string;
}
export interface PlatformSelect {
    ios: string;
    android: string;
}
export interface AppVersionSelect {
    starwalk2: string;
    "starwalk2-free": string;
}
const platformSelectOptions = {
    ios: "iOS",
    android: "Android",
};
const versionSelectOptions = {
    starwalk2: "Star Walk 2",
    "starwalk2-free": "Star Walk 2 Free",
};

const ContactForm: React.FC<Props> = ({ formData, contactButton }) => {
    const [isFormOpened, setIsFormOpened] = useState<boolean>(false);
    const { emailValidationText, inputValidationText, captchaValidationText } =
        formData;
    const onClose = () => {
        setIsFormOpened(false);
    };

    const [sendMessage, { loading }] = useMutation<
        { sendMessageToSupport: SendMessagePayload },
        { input: SendMessageInput }
    >(CREATE_MESSAGE);

    const onSubmit = async (value: Values) => {
        const platform =
            value.platform === "android" ? "google" : value.platform;

        const dataToSave: SendMessageInput = {
            to: value.department,
            source: VITO_APP_ID,
            productInfo: {
                productId: "star-walk-2",
                platform: value.platform,
                appId: value.version
                    ? `${value.version}-${platform}`
                    : undefined,
            },
            email: value.email,
            name: value.name,
            message: value.message,
        };
        try {
            await sendMessage({
                variables: { input: dataToSave },
            });
        } catch (e) {
            return { "FINAL_FORM/form-error": "ERROR" };
        }
    };

    const required = makeRequiredValidator(inputValidationText);

    if (isFormOpened) {
        return (
            <Form
                onSubmit={onSubmit}
                render={({
                    handleSubmit,
                    submitSucceeded,
                    submitting,
                    hasSubmitErrors,
                    validating,
                    form,
                }) => (
                    <div className={formWrapper}>
                        <div className={titleBox}>
                            <h3 className={formTitle}>{formData.formTitle}</h3>

                            <button
                                className={closeFormButton}
                                onClick={onClose}
                            >
                                <Icon k="close" />
                            </button>
                        </div>
                        <form className={contentBox}>
                            <SelectField
                                name="department"
                                label={`${formData.departmentSelectLabel} *`}
                                optionsData={formData.departmentSelect}
                                validator={required}
                            />

                            <TextField
                                name="name"
                                label={`${formData.nameInputLabel} *`}
                                placeholder={formData.nameInputPlaceholder}
                                validator={required}
                            />

                            <TextField
                                name="email"
                                label={`${formData.emailInputLabel} *`}
                                placeholder={formData.emailInputPlaceholder}
                                validator={makeEmailValidator(
                                    emailValidationText,
                                )}
                            />

                            <Condition when="department" is="Support">
                                <>
                                    <SelectField
                                        name="platform"
                                        label={`${formData.platformSelectLabel} *`}
                                        optionsData={platformSelectOptions}
                                        validator={required}
                                    />
                                    <SelectField
                                        name="version"
                                        label={`${formData.versionSelectLabel} *`}
                                        optionsData={versionSelectOptions}
                                        validator={required}
                                    />
                                </>
                            </Condition>

                            <TextAreaField
                                name="message"
                                label={`${formData.messageInputLabel} *`}
                                validator={required}
                            />

                            <Field
                                name="captcha"
                                component={Captcha}
                                buttonText={formData.okButton}
                                title={formData.captchaTitle}
                                validate={makeCaptchaValidator(
                                    captchaValidationText,
                                )}
                            />
                            <div className={buttonBox}>
                                <button
                                    className={sendButton}
                                    type="button"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                    disabled={submitting || validating}
                                >
                                    {!loading && (
                                        <span>{formData.submitButton}</span>
                                    )}
                                    {loading && <CircleSpinner />}
                                </button>
                            </div>
                            {hasSubmitErrors ? (
                                <p
                                    className={
                                        hasSubmitErrors
                                            ? errorMessageText
                                            : messageText
                                    }
                                >
                                    {formData.errorMessageText}
                                </p>
                            ) : null}
                        </form>
                        {submitSucceeded ? (
                            <div className={popupWrapper}>
                                <div className={popupMessageBox}>
                                    <p
                                        className={
                                            hasSubmitErrors
                                                ? errorMessageText
                                                : messageText
                                        }
                                    >
                                        {formData.popupMessageText}
                                    </p>
                                    <button
                                        className={successMessageButton}
                                        onClick={() => {
                                            onClose();
                                            form.restart();
                                        }}
                                    >
                                        {formData.okButton}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            />
        );
    }

    return (
        <button
            className={contactsButton}
            onClick={() => {
                setIsFormOpened(true);
            }}
        >
            {contactButton}
        </button>
    );
};

export default ContactForm;
