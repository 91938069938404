import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";
import {
    CommonContent,
    NewsFeedPageContent,
    NewsPageContent,
    TutorialsListPageContent,
    GamesListPageContent,
    InfographicsFeedPageContent,
    InfographicPageContent,
    SearchPageContent,
    QuizPageContent,
} from "../../i18n/content.js";
import { App } from "./apps.js";
import { IImagePreview } from "./gallery.js";
import { imagePreview, mediaItemPreview } from "./fragments.js";
import { ConfigData, ImageABTestConfig } from "../../config/config-context.js";

export interface GetCommonContentVariables {
    langCode?: LangCode;
}

export interface GetCommonContentData {
    langs: {
        id: string;
        contents: {
            langCode: LangCode;
            value: string;
        }[];
    };
    common: {
        id: string;
        content: CommonContent;
    };
    newsFeedPage: {
        id: string;
        content: NewsFeedPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    newsPage: {
        id: string;
        content: NewsPageContent;
    };
    tutorialsListPage: {
        id: string;
        content: TutorialsListPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    gamesListPage: {
        id: string;
        content: GamesListPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    infographicsFeedPage: {
        id: string;
        content: InfographicsFeedPageContent;
        mediaItems: (IImagePreview | null)[] | null;
    };
    infographicPage: {
        id: string;
        content: InfographicPageContent;
    };
    searchPage: {
        id: string;
        content: SearchPageContent;
    };
    app: {
        config: Partial<ConfigData> | null;
        promoApps: (App | null)[];
        abTestsConfigs: ImageABTestConfig[] | null;
    } | null;
    quizPage: {
        id: string;
        content: QuizPageContent;
    };
}

export const GET_COMMON_CONTENT = gql`
    query getCommonContent($langCode: LangCode) {
        app {
            config
            promoApps {
                id
                title
                platform
                icon {
                    sourceUrl
                    sizes
                }
                marketId
                marketUrl
            }
            abTestsConfigs {
                imageId
                experimentKey
                altImages {
                    ...imagePreview
                }
            }
        }
        langs: staticContent(id: "starwalk-site-languages") {
            id
            contents
        }
        common: staticContent(id: "starwalk-site-common") {
            id
            content(langCode: $langCode)
        }
        newsFeedPage: staticContent(id: "starwalk-site-news-feed-page") {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        newsPage: staticContent(id: "starwalk-site-news-page") {
            id
            content(langCode: $langCode)
        }
        tutorialsListPage: staticContent(
            id: "starwalk-site-tutorials-list-page"
        ) {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        gamesListPage: staticContent(id: "starwalk-site-games-list-page") {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        infographicsFeedPage: staticContent(
            id: "starwalk-site-infographics-feed-page"
        ) {
            id
            content(langCode: $langCode)
            mediaItems {
                ...mediaItemPreview
            }
        }
        infographicPage: staticContent(id: "starwalk-site-infographic-page") {
            id
            content(langCode: $langCode)
        }
        searchPage: staticContent(id: "starwalk-site-search-page") {
            id
            content(langCode: $langCode)
        }
        quizPage: staticContent(id: "starwalk-site-quiz-page") {
            id
            content(langCode: $langCode)
        }
    }
    ${mediaItemPreview}
    ${imagePreview}
`;
