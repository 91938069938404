import React, { useContext } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { Link } from "react-router-dom";

import {
    GET_GAMES_FEED_FOR_SITEMAP,
    GET_INFOGRAPHICS_FEED_FOR_SITEMAP,
    GET_NEWS_FEED_FOR_SITEMAP,
    GET_SITEMAP_STATIC_CONTENT,
    GET_TOPICS_FEED_FOR_SITEMAP,
    GET_TUTORIALS_FEED_FOR_SITEMAP,
    SitemapStaticData,
} from "../../graphql/queries/sitemap.js";

import Content from "../../i18n/content.js";
import type { LangCode } from "../../../_common/types.js";
import { langCodes } from "../../../_common/lang-codes.js";
import { container } from "../styles/common.css.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import MetaTags from "../MetaTags.js";
import Spinner from "../Spinner.js";
import LinksListLoader from "./LinksListLoader.js";
import {
    itemHeader,
    itemHeaderLink,
    pageTitle,
    sectionItem,
    sectionList,
} from "./Sitemap.css.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

const Sitemap: React.FC = () => {
    const path = "/sitemap";
    const {
        langCode,
        common: {
            layout: {
                footer: { terms, privacy, about, contact },
                menu: { home, news, tutorials, games, infographics, moon },
            },
            relatedTopics,
        },
    } = useContext(Content);

    const { loading, error, data } = useQuery<
        SitemapStaticData,
        { langCode: LangCode }
    >(GET_SITEMAP_STATIC_CONTENT, {
        variables: {
            langCode: langCode,
        },
    });

    const sitemapItems = [
        {
            path: "",
            key: "home",
            text: home,
        },
        {
            path: "/contacts",
            key: "contacts",
            text: contact,
        },
        {
            path: "/about",
            key: "about",
            text: about,
        },
        {
            path: "/moon-calendar",
            key: "moon",
            text: moon,
        },
        {
            path: "/privacy-policy",
            key: "privacy-policy",
            text: privacy,
        },
        {
            path: "/terms-of-use",
            key: "terms-of-use",
            text: terms,
        },
        {
            path: "/tutorials",
            key: "tutorials",
            text: tutorials,
            query: GET_TUTORIALS_FEED_FOR_SITEMAP,
        },
        {
            path: "/infographics",
            key: "infographics",
            text: infographics,
            query: GET_INFOGRAPHICS_FEED_FOR_SITEMAP,
        },
        {
            path: "/games",
            childPath: "/quiz",
            key: "games",
            text: games,
            query: GET_GAMES_FEED_FOR_SITEMAP,
        },
        {
            path: "/topics",
            stubHeader: true,
            key: "topics",
            text: relatedTopics,
            query: GET_TOPICS_FEED_FOR_SITEMAP,
        },
        {
            path: "/news",
            key: "news",
            text: news,
            query: GET_NEWS_FEED_FOR_SITEMAP,
        },
    ];

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }
    if (data) {
        const {
            staticContent: {
                content: { title, metaTitle, metaDescription },
            },
        } = data;
        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={langCodes}
                    path={path}
                    metaTitle={metaTitle}
                    title={title}
                    description={metaDescription}
                />
                <main className={container.flex1000}>
                    <h1 className={pageTitle}>{title}</h1>
                    <ul className={sectionList}>
                        {sitemapItems.map(
                            ({
                                path,
                                key,
                                text,
                                query,
                                stubHeader,
                                childPath,
                            }) => (
                                <li key={key} className={sectionItem}>
                                    {!stubHeader ? (
                                        <Link
                                            className={itemHeaderLink}
                                            to={`/${langCode}${path}`}
                                        >
                                            {text}
                                        </Link>
                                    ) : (
                                        <div className={itemHeader}>{text}</div>
                                    )}

                                    {query && (
                                        <LinksListLoader
                                            query={query}
                                            langCode={langCode}
                                            rootPath={childPath || path}
                                        />
                                    )}
                                </li>
                            ),
                        )}
                    </ul>
                </main>
            </>
        );
    }
    return <NotFoundErrorMsg />;
};

export default Sitemap;
