import gql from "graphql-tag";

export interface Partner {
    id: string;
    content: {
        langCode: string;
        name: string;
    };
    url: string;
    imageUrl: string;
    rel?: string;
}

export interface AboutPageContent {
    metaTitle: string;
    metaDescription: string;
    title: string;
    text: string;
    partnersTitle: string;
    awardsTitle: string;
    keywords?: string;
}

export interface GetAboutContentData<T> {
    staticContent: {
        id: string;
        content: T;
    };
    product: {
        content: {
            awards: {
                url: string | undefined;
                imgUrl: string;
                alt: string;
                rel?: string;
            }[];
        };
    };
    partners: Partner[] | null;
}

export const GET_ABOUT_CONTENT = gql`
    query getAboutContent($langCode: LangCode) {
        staticContent(id: "starwalk-site-about-page") {
            id
            content(langCode: $langCode)
        }
        product(id: "star-walk-2") {
            content {
                awards {
                    imgUrl
                    alt
                    url
                }
            }
        }
        partners {
            id
            content {
                name
            }
            url
            imageUrl
        }
    }
`;
