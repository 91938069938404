import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";
import { FeedConnection } from "./_common.js";

export interface SitemapStaticData {
    staticContent: {
        id: string;
        content: {
            title: string;
            metaTitle: string;
            metaDescription: string;
        };
    };
}

export interface SitemapLinkData {
    id: string;
    content: {
        title: string;
    };
}

export interface SitemapListData {
    feed: FeedConnection<SitemapLinkData>;
}

export interface GetFeedForSitemapVariables {
    first: number;
    cursor?: string;
    langCode: LangCode;
}

export const GET_SITEMAP_STATIC_CONTENT = gql`
    query getSitemapStaticContent($langCode: LangCode) {
        staticContent(id: "starwalk-site-sitemap-page") {
            content(langCode: $langCode)
        }
    }
`;

export const GET_NEWS_FEED_FOR_SITEMAP = gql`
    query getNewsFeedForSitemap(
        $first: Int
        $cursor: NewsCursor
        $langCode: LangCode
    ) {
        feed: newsFeed(first: $first, after: $cursor) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    content(langCode: $langCode) {
                        title
                    }
                }
            }
        }
    }
`;

export const GET_TUTORIALS_FEED_FOR_SITEMAP = gql`
    query getTutorialsFeedForSitemap(
        $first: Int
        $cursor: TutorialCursor
        $langCode: LangCode
    ) {
        feed: tutorials(first: $first, after: $cursor) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    content(langCode: $langCode) {
                        title
                    }
                }
            }
        }
    }
`;

export const GET_GAMES_FEED_FOR_SITEMAP = gql`
    query getGamesFeedForSitemap(
        $first: Int
        $cursor: QuizCursor
        $langCode: LangCode
    ) {
        feed: quizzes(first: $first, after: $cursor) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    content(langCode: $langCode) {
                        title
                    }
                }
            }
        }
    }
`;

export const GET_INFOGRAPHICS_FEED_FOR_SITEMAP = gql`
    query getInfographicsFeedForSitemap(
        $first: Int
        $cursor: InfographicsCursor
        $langCode: LangCode
    ) {
        feed: infographicsFeed(first: $first, after: $cursor) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    content(langCode: $langCode) {
                        title
                    }
                }
            }
        }
    }
`;
export const GET_TOPICS_FEED_FOR_SITEMAP = gql`
    query getTopicsFeedForSitemap(
        $first: Int
        $cursor: TopicCursor
        $langCode: LangCode
    ) {
        feed: topics(first: $first, after: $cursor) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    content(langCode: $langCode) {
                        title
                    }
                }
            }
        }
    }
`;
