import gql from "graphql-tag";

export interface FormContent {
    formTitle: string;
    nameInputLabel: string;
    nameInputPlaceholder: string;
    emailInputLabel: string;
    emailInputPlaceholder: string;
    messageInputLabel: string;
    errorMessageText: string;
    inputValidationText: string;
    popupMessageText: string;
    okButton: string;
    emailValidationText: string;
    captchaValidationText: string;
    submitButton: string;
    captchaTitle: string;
    departmentSelectLabel: string;
    departmentSelectOptions: string[];
    departmentSupport: string;
    platformSelectLabel: string;
    platformSelectOptions: string[];
    versionSelectLabel: string;
    versionSelectOptions: string[];
    departmentSelect: DepartmentSelect;
}
export interface DepartmentSelect {
    Support: string;
    Marketing: string;
    Team: string;
}
export interface ContactsPageContent {
    metaTitle: string;
    pageTitle: string;
    locationTitle: string;
    metaDescription: string;
    contactsText: string;
    addressOne: string;
    addressTwo: string;
    contactButton: string;
    formContent: FormContent;
    keywords?: string;
}

export interface GetContactsData {
    staticContent: {
        id: string;
        content: ContactsPageContent;
    };
}

export const GET_CONTACTS = gql`
    query getContacs($langCode: LangCode) {
        staticContent(id: "starwalk-site-contacts") {
            id
            content(langCode: $langCode)
        }
    }
`;

export type AppPlatform =
    | "ios"
    | "android"
    | "huawei"
    | "wsa"
    | "web"
    | "editor";

export type Department = "Support" | "Marketing" | "Team";
export interface SendMessageInput {
    to: Department;
    source: string;
    productInfo?: {
        productId?: string;
        platform?: AppPlatform;
        appId?: string;
    };
    email: string;
    name: string;
    message: string;
}

export interface SendMessagePayload {
    ok: boolean;
    message: string;
}

export const CREATE_MESSAGE = gql`
    mutation sendMessage($input: SendMessageInput!) {
        sendMessage(input: $input) {
            ok
            message
        }
    }
`;
